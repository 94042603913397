import gql from 'graphql-tag'
import { Image } from './ImageType'

export const ImageGroupImage = gql`
  ${Image}
  fragment ImageGroupImage on NewImageGroupImageType {
    id
    altText
    imagekitSettings
    mainText
    position
    secondaryText
    url
    image {
      ...Image
    }
  }
`
