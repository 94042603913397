import gql from 'graphql-tag'

export const Image = gql`
  fragment Image on NewImageType {
    id
    identifier
    originalImage
    mobileImage
    caption
  }
`
